import React, { useState } from "react";
import styled from "styled-components";
import CellarFiltersModal from "./CellarFiltersModal";
import { Badge, FlexContainer } from "../../../../Components/Shared";
import { flex } from "../../../../Styles/tools";
import FilterBadge from "./FilterBadge";
import { ICellarFilters } from "../../../../Reducers/Filters";
import { FiltersIcon } from "../../../../Components/Shared/icons";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { useTranslation } from "react-i18next";
import { HideHorizontalScrollBar } from "Styles/mixins";
import { getGlobalLanguage } from "Utils/language.helper";

const FilterContainer = styled.div`
	${flex("column", "center", "flex-start", "nowrap")};
	position: relative;
`;

const SelectedFiltersContainer = styled.div`
	overflow-y: scroll;
	width: 100%;
	${flex("row", "flex-start", "center", "nowrap")};
	${HideHorizontalScrollBar()}
`;

interface CellarFiltersProps {
	filtersState: ICellarFilters;
	handleFilterChange: (name: string, value: string) => void;
	loading: boolean;
}
const CellarFilters: React.FC<CellarFiltersProps> = ({ filtersState, handleFilterChange, loading }) => {
	const { winesTotal } = useSelector((state: AppState) => state.wines);
	const selections = useSelector((state: AppState) => state.selections.data);
	const [showFiltersModal, setshowFiltersModal] = useState(false);
	const openModal = () => setshowFiltersModal(true);
	const closeModal = () => setshowFiltersModal(false);
	const { t, i18n } = useTranslation();

	const currenLang = getGlobalLanguage(i18n.language).toUpperCase();


	const clearFilter = (id: string) => {
		handleFilterChange(id, "");
	};

	return (
		<>
			<FilterContainer className="filter-container">
				<FlexContainer width="100%" margin="1rem 0" direction="row" justify="space-between">
					<FiltersIcon onClick={openModal} />
					<h6
						style={{
							color: "black",
							marginRight: "1em",
						}}
					>
						{loading ? `${t("common.loading")}...` : `${winesTotal} ${t("common.labels")}`}
					</h6>
				</FlexContainer>
				<SelectedFiltersContainer className="selected-filters-container">
					{Object.keys(filtersState).map(
						(filterKey: string) => {
							let badgeLabel = filtersState[filterKey as keyof typeof filtersState]
							if(filterKey === "selection") {
								let selection = selections.find((sel: any) => sel.label === badgeLabel);
								badgeLabel = selection ? selection[`title_${currenLang.toLocaleLowerCase()}`] : "";
								if(badgeLabel.length === 0){
									badgeLabel = t("common.noInfoAvailableInThisLanguage");
								}
							}
							return filtersState[filterKey as keyof typeof filtersState] !== "" && (
								<Badge margin="0 1em" color="transparent" textColor="black" key={filterKey}>
									<FilterBadge
										id={filterKey}
										label={badgeLabel}
										clear={clearFilter}
									/>
								</Badge>
							)
						}
					)}
				</SelectedFiltersContainer>
			</FilterContainer>
			<CellarFiltersModal isOpen={showFiltersModal} close={closeModal} onChange={handleFilterChange} />
		</>
	);
};

export default CellarFilters;
