import React, { useState } from "react";
import styled from "styled-components";
import { FaWineBottle, FaWineGlassAlt } from "react-icons/fa";
import { URL_INTEGRAL } from "env";
import { Wine } from "../../../Types";
import { flex } from "../../../Styles/tools";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../../../Components/Shared";
import noImageBlackBottle from "Assets/images/bottle/bottle_default.png"
import { AppState } from "store";
import { useSelector } from "react-redux";
import { formatCurrencyAmount } from "Utils";
import { getGlobalLanguage } from "Utils/language.helper";
import { Subtitle } from "Components/Core";

interface WineListItem {
	wine: Wine;
}

const WineListItemContainer = styled.div`
	${flex("row", "flex-start", "center", "nowrap")}
	width: 100%;
`;

const WineName = styled.h5`
	color: ${({ theme }) => theme.colors.black};
	font-family: CenturyGothicBold;
`;

const WinePrice = styled.h6`
	color: ${({ theme }) => theme.colors.light_black};
`;

const WineBottleIcon = styled(FaWineBottle)`
	color: ${({ theme }) => theme.colors.light_black};
	margin-top: 2%;
`;

const WineGlassIcon = styled(FaWineGlassAlt)`
	color: ${({ theme }) => theme.colors.light_black};
	margin-top: 2%;
`;

const WineDescription = styled.div`
	margin-top: 5px;
	color: ${({ theme }) => theme.colors.gray};
`;

const BottleImageMini = styled.img`
	height: 100px;
	margin-right: 20px;
`;

const DEFAULT_CURRENCY_DIGITS = 2;
const NO_CURRENCY_DIGITS = 0;

const LabelDataContainer = styled.span``;

const WineListItem: React.FC<WineListItem> = ({ wine }: WineListItem) => {
	const { i18n, t } = useTranslation();
	const currentLang = getGlobalLanguage(i18n.language).toUpperCase();
	const client = useSelector((state: AppState) => state.client.data);
	const [imageLoaded, setImageLoaded] = useState(false);
	const decimals = client?.client_settings.decimals === undefined? DEFAULT_CURRENCY_DIGITS: client?.client_settings.decimals? DEFAULT_CURRENCY_DIGITS : NO_CURRENCY_DIGITS;

	const imgObject = new Image();
	imgObject.onload = function(){
		setImageLoaded(true);
	}

	const image_url = `${URL_INTEGRAL}/wines/image/${client?.clientid}/${wine.image}`;

	imgObject.src = image_url;

	return (
		<WineListItemContainer className="wine-list-item-container">
			{wine.image && (
				<BottleImageMini src={imageLoaded ? image_url : noImageBlackBottle} alt="bottleImage" />
			)}

			<LabelDataContainer>
				{/* Wine name is now hardcoded to ES due every label has same name on differente languages. This prevent fails on new Portuguese language */}
				<WineName className="wine-name">{wine[`nameES` as keyof typeof wine]}</WineName>
				<WineDescription>{`${wine.year ? `${wine.year} ,` : ""} ${
					currentLang === "ES"
						? wine.country
						: wine[`country${currentLang}` as keyof typeof wine] || t("common.noInfoAvailableInThisLanguage")
				}`}</WineDescription>
				{wine.sellPrice !== null && wine.sellPrice > 0 && wine.size && wine.size > 0 && (
					<FlexContainer width="100%" margin="0.5em 0" align="center" className="wine-bottle-info-row">
						<FlexContainer margin="0" align="center" className="wine-bottle-price">
							<WineBottleIcon size={12} />
							<WinePrice>{`${formatCurrencyAmount(wine.sellPrice, client?.main_currency, decimals)}`}</WinePrice>
						</FlexContainer>
						<FlexContainer margin="0 0 0 2rem" align="center" className="wine-bottle-size">
							<Subtitle color="gray" margin="0" size="0.9rem">{`${wine.size} ml.`}</Subtitle>
						</FlexContainer>
					</FlexContainer>
				)}
				{wine.byGlass !== null && wine.glassPrice > 0 && wine.byGlass && wine.glasses_num > 0 && (
					<FlexContainer width="100%" margin="0.5em 0" align="center" className="wine-glass-info-row">
						<FlexContainer margin="0" align="center" className="wine-glass-price">
							<WineGlassIcon size={12} />
							<WinePrice>{`${formatCurrencyAmount(wine.glassPrice, client?.main_currency, decimals)}`}</WinePrice>
						</FlexContainer>
						<FlexContainer margin="0 0 0 2rem" align="center" className="wine-bottle-size">
							<Subtitle color="gray" margin="0" size="0.9rem">{`${Math.round(
								wine.size / wine.glasses_num
							)} ml.`}</Subtitle>
						</FlexContainer>
					</FlexContainer>
				)}
			</LabelDataContainer>
		</WineListItemContainer>
	);
};

export default WineListItem;