import React from "react";
import styled from "styled-components";

import { Page, PageHeader, Title, FlexContainer } from "../../../../Components/Shared";
import { ClientURL, Wine } from "../../../../Types";
import PageContent from "../../../../Components/Shared/Page/PageContent";
import { useTranslation } from "react-i18next";
import { flex } from "../../../../Styles/tools";
import WineBottle from "Components/Shared/WineBottle/WineBottle";
import { AppState } from "store";
import { useSelector } from "react-redux";
import { formatCurrencyAmount, getFlagCode } from "Utils";
import { getGlobalLanguage } from "Utils/language.helper";
import { Subtitle } from "Components/Core";
import { useParams } from "react-router-dom";

interface DescriptionBox {
	border?: boolean;
}


/** Export Manager table Configuration */
const configTable = [
	{flag:false, name:50, email:50, phoneflag: false},
	{flag:false, name:40, email:40, phoneflag: true, phone:20},
	{flag:true, name:50, email:45, phoneflag: false},
	{flag:true, name:40, email:35, phoneflag: true, phone:20 },
];

const DescriptionBox = styled.div<DescriptionBox>`
	${flex("row", "space-between", "baseline", "nowrap")}
	padding: 10px 0;
	width: 100%;
	border-bottom: ${({ theme, border = true }) => border && `1px solid ${theme.colors.transparent}`};
`;

const Label = styled.span<any>`
	text-align: ${({ align = "left" }) => align};
`;

const LabelDescription = styled.h5`
	color: ${({ theme }) => theme.colors.gray};
	font-size: 0.8em;
	line-height: 1.6;
`;

const HeaderTable = styled.div`
	border-bottom: 1px solid #bcbcbc;
	display: flex;
	width: 100%;
	margin-top: 10px;
`;

const CellHeader = styled.div<{ Size?:number; }>`
	display: inline-block;
	width: ${props => props.Size}%;
	font-size: 0.8rem;
	padding-bottom:8px;
	font-weight: bold;
`;

const BodyTable = styled.div`
	border-bottom: 1px solid #dedee0;
	display: flex;
	width: 100%;
	margin-top: 10px;
`;

const CellBody = styled.div<{ Size?:number; TextAlign?:string }>`
	display: inline-block;
	width: ${props => props.Size}%;
	font-size: 0.7rem;
	padding-bottom:8px;
	word-wrap: break-word;
	text-align: ${props => props.TextAlign?props.TextAlign:"left"};
`;

const CellFlag =styled.div`
	display: inline-block;
	width: 5%;
	font-size: 0.7rem;
	padding-bottom:8px;
	padding-top: 1px
`;

const configMangerTable = (managers: any[]) =>{
	let phone = false;
	let flag = false;

	managers.forEach((value, key) => {
		if(value.phone){phone = true;}
		if(value.country){flag = true;}
	});

	let selection = configTable.find((value) => value.flag === flag && value.phoneflag === phone);

	return selection;
};

const DEFAULT_CURRENCY_DIGITS = 2;
const NO_CURRENCY_DIGITS = 0;


interface WineDetailPageProps {
	close: () => void;
	wine: Wine | null;
	idWine: string;
}
const WineDetailPage: React.FC<WineDetailPageProps> = ({ wine, close, idWine }: WineDetailPageProps) => {
	const { t, i18n } = useTranslation();
	const { clientId } = useParams<ClientURL>();
	const client = useSelector((state: AppState) => state.client?.data);
	const currentLang = getGlobalLanguage(i18n.language).toUpperCase();
	const decimals = client?.client_settings.decimals === undefined? DEFAULT_CURRENCY_DIGITS: client?.client_settings.decimals? DEFAULT_CURRENCY_DIGITS : NO_CURRENCY_DIGITS;
	const wineData = {};
	let table = {flag: false, name: 0, email: 0, phoneflag: false, phone: 0};
	if(wineData){
		//@ts-ignore
		table = configMangerTable(wine?.exportManager || []);
	}

	return (
		<Page>
			<PageHeader
				color="black"
				textColor="white"
				backEnabled={true}
				backText={t("common.return")}
				customBackAction={close}
			/>
			<PageContent className="wine-page-content">
				<FlexContainer width="100%" padding="0 20px 1em 20px" direction="column" justify="center" align="center">
					{/* Bottle Image */}
					<WineBottle clientId={clientId} wineImageName={wine ? wine.image : ""} />

					<Title className="bold" color="black" margin="1rem auto 2rem auto">
						{wine ? wine[`name${currentLang}` as keyof typeof wine] || wine.nameES : t("common.loading")}
					</Title>
					{/* Varietal */}
					<DescriptionBox className="description-box-variety">
						<Label>{t(`wineDetail.variety`)}</Label>
						<Label className="bold" align="right">
							{wine ? wine[`varietal${currentLang}` as keyof typeof wine] || t("common.noInfoAvailableInThisLanguage") : "---"}
						</Label>
					</DescriptionBox>
					{/* Country */}
					<DescriptionBox className="description-box-country">
						<Label>{t(`wineDetail.country`)}</Label>
						<Label className="bold" align="right">
							{wine ? currentLang === "ES"
								? wine.country
								: wine[`country${currentLang}` as keyof typeof wine] || t("common.noInfoAvailableInThisLanguage")
								: "---"}
						</Label>
					</DescriptionBox>
					{/* House */}
					<DescriptionBox className="description-box-house">
						<Label>{t(`wineDetail.house`)}</Label>
						<Label className="bold" align="right">
							{wine ? wine.house : "---"}
						</Label>
					</DescriptionBox>
					{/* Region */}
					<DescriptionBox className="description-box-region">
						<Label>{t(`wineDetail.region`)}</Label>
						<Label className="bold" align="right">
							{wine ? wine.region : "---"}
						</Label>
					</DescriptionBox>
					{/* Subregion */}
					<DescriptionBox className="description-box-subregion">
						<Label>{t(`wineDetail.subregion`)}</Label>
						<Label className="bold" align="right">
							{wine ? wine.subregion : "---"}
						</Label>
					</DescriptionBox>
					{/* Type */}
					<DescriptionBox className="description-box-type">
						<Label>{t(`wineDetail.type`)}</Label>
						<Label className="bold" align="right">
							{wine ? wine[`type${currentLang}` as keyof typeof wine] || t("common.noInfoAvailableInThisLanguage") : "---"}
						</Label>
					</DescriptionBox>
					{/* Year */}
					<DescriptionBox className="description-box-year">
						<Label>{t(`wineDetail.year`)}</Label>
						<Label className="bold" align="right">
							{wine ? wine.year ? wine.year : t("common.noAvailable") : "---"}
						</Label>
					</DescriptionBox>
					{/* Price by bottle */}
					{wine && wine.sellPrice !== null && wine.sellPrice !== 0 && (
						<DescriptionBox className="description-box-price">
							<Label>{t(`wineDetail.priceBottle`)}</Label>
							<Label className="bold" align="right">{`${formatCurrencyAmount(
								wine.sellPrice,
								client?.main_currency,
								decimals
							)}`}</Label>
						</DescriptionBox>
					)}
					{/* Bottle Size */}
					<DescriptionBox className="description-box-size">
						<Label>{t(`wineDetail.size`)}</Label>
						<Label className="bold" align="right">
							{wine ? wine.size : "---"} ml.
						</Label>
					</DescriptionBox>
					{/* Price by glass */}
					{wine && wine.byGlass && wine.glassPrice !== 0 && (
						<DescriptionBox className="description-box-price">
							<Label>{t(`wineDetail.priceGlass`)}</Label>
							<Label className="bold" align="right">{`${formatCurrencyAmount(
								wine.glassPrice,
								client?.main_currency,
								decimals
							)}`}</Label>
						</DescriptionBox>
					)}
					{/* Glass Size */}
					{wine && wine.byGlass && wine.glasses_num > 0 && (
						<DescriptionBox className="description-box-size">
							<Label>{t(`wineDetail.glassSize`)}</Label>
							<Label className="bold" align="right">
								{Math.round(wine.size / wine.glasses_num)} ml.
							</Label>
						</DescriptionBox>
					)}

					{/* Export Manager */}
					{wine && wine.exportManager.length > 0 && (
						<><Subtitle color="black" margin="10px 0px 0px 0px ">
							{t(`wineDetail.export`)}
						</Subtitle>
						
						<HeaderTable>
							{table?.flag?<CellFlag><span className={`fi`}></span></CellFlag>:""}
							<CellHeader Size={table?.name}>{t(`common.name`)}</CellHeader>
							<CellHeader Size={table?.email}>{t(`common.email`)}</CellHeader>
							{table?.phoneflag?<CellHeader Size={table?.phone}>{t(`common.phone`)}</CellHeader>:""}
						</HeaderTable>
						
						{
							wine.exportManager.map((value, key) => {
								let codeflag = getFlagCode(value.country);
								return (
									<BodyTable key={key}>
										{table?.flag?<CellFlag><span className={`fi fi-${codeflag?.code}`}></span></CellFlag>:""}
										<CellBody Size={table?.name}>{value.name}</CellBody>
										<CellBody Size={table?.email}>{value.email}</CellBody>
										{table?.phoneflag?<CellBody Size={table?.phone} TextAlign="right">{value.phone}</CellBody>:""}
									</BodyTable>
								)
						  	})
						}
						</>
					)}	
					

					{/* Description */}
					
					<Subtitle color="black" margin="10px 0px 0px 0px ">
						{t(`wineDetail.description`)}
					</Subtitle>	
					<DescriptionBox className="description-box-description" border={false}>
						<LabelDescription>
							{wine ? wine[`desc${currentLang.toUpperCase()}` as keyof typeof wine] ||
								t("common.noInfoAvailableInThisLanguage")
								: "---"}
						</LabelDescription>
					</DescriptionBox>
				</FlexContainer>
			</PageContent>
		</Page>
	);
};

export default WineDetailPage;
