import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { AppState } from "store";
import { Title, FlexContainer, ListItem } from "Components/Shared";
import { flex } from "Styles/tools";
import { useParams } from "react-router-dom";
import { ClientType, ClientURL } from "Types";

const RestaurantHeader = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	padding: 25px 15px;
	position: absolute;
	width: 100%;
`;

const RestaurantImg = styled.img`
	width: 100%;
	height: 50vh;
	@media screen and (min-width: ${({ theme }) => theme.screen_sizes.l}) {
		height: 80vh;
	}
`;

const RestaurantInfo = styled.div`
	${flex("column", "flex-start", "center", "nowrap")};
	padding: 6.75rem 0 0 0;
	overflow-y: scroll;
	height: auto;
	padding-bottom: 1.6em;
`;

const InfoText = styled.h6`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.blue};
	margin-right: 0.6em;
`;

const InfoLabel = styled.span`
	font-family: CenturyGothicBold;
	margin-bottom: 3px;
`;

const RestaurantDetail: React.FC = () => {
	const { restaurantId } = useParams<ClientURL>();
	const { t } = useTranslation();

	const { dish } = useSelector((store: AppState) => store.categories);
	const { data, cellarLength } = useSelector((state: AppState) => state.wines);
	const dishes = useSelector((state: AppState) => state.dishes);
	const pairings = useSelector((store: AppState) => store.client.data?.pairings);
	const client = useSelector((store: AppState) => store.client);
	

	// TODO: remove next custom hook when client data contains cellar length (pending task on Backend)
	

	let clientImage = client.data?.main_image1_url
		? `http://wa-client-media.s3.amazonaws.com/${client.data?.main_image1_url}`
		: require("Assets/placeholders/RestaurantImg.jpg");

	
	const isJson = (str:any) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}	

	let nombre_evento = "";
	if(client.data?.client_type === "private-event"){
		if(isJson(client.data?.description)){
			let parsedClientDescription = JSON.parse(client.data?.description)
			nombre_evento = parsedClientDescription.nombre_evento
		}
	}
	let clientName = client.data?.client_type === "private-event" ? nombre_evento : client.data?.description;

	if (client.data?.client_type === ClientType.HOTEL) {
		const selectedRestaurant = client.data.restaurants.find((rest: any) => rest._id === restaurantId);
		clientImage =
			selectedRestaurant && selectedRestaurant.logo
				? `http://wa-client-media.s3.amazonaws.com/${selectedRestaurant.logo}`
				: require("Assets/placeholders/RestaurantImg.jpg");
		clientName = selectedRestaurant ? selectedRestaurant.name : client.data?.description;
	}

	return (
		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 0 }}>
			<RestaurantHeader className="restaurant-header">
				<Title className="bold" wrap="break-word">
					{clientName}
				</Title>
				<FlexContainer width="100%" margin="1em 0 0 0">
					<h6 style={{ color: "white" }}>{`${t("common.cellar")}: ${
						cellarLength === 0 && data === null ? t("common.loading") : cellarLength || 0
					} ${t("common.labels")}${cellarLength === 0 && data === null ? "..." : ""}`}</h6>
					{pairings && (
						<h6
							style={{
								color: "white",
								marginLeft: "1em",
							}}
						>
							{`${t("common.menu")}: ${dish && dishes.data ? dishes.dishesTotal : t("common.loading")} ${t(
								"common.dishes"
							)}${dish ? "" : "..."}`}
						</h6>
					)}
				</FlexContainer>
			</RestaurantHeader>

			<RestaurantInfo className="restaurant-info">
				<RestaurantImg src={clientImage} />
				<FlexContainer direction="column" width="100%" padding="0 10px">
					{client.data?.about_us && (
						<ListItem hideIcon={true}>
							<FlexContainer direction="column" width="100%">
								<InfoLabel>{t("common.about_us")}</InfoLabel>
								<InfoText>
									{client.data?.about_us}
								</InfoText>
							</FlexContainer>
						</ListItem>
					)}
					{client.data?.address && (
						<ListItem hideIcon={true}>
							<FlexContainer direction="column" width="100%">
								<InfoLabel>{t("common.address")}</InfoLabel>
								<InfoText
									as="a"
									href={`https://www.google.com/maps/search/?api=1&query=${client.data?.address}`}
									target="_blank"
								>
									{client.data?.address}
								</InfoText>
							</FlexContainer>
						</ListItem>
					)}
					{client.data?.phone && (
						<ListItem hideIcon={true}>
							<FlexContainer direction="column" width="100%">
								<InfoLabel>{t("common.phone")}</InfoLabel>
								<InfoText as="a" href={`tel:${client.data?.phone}`} target="_blank">
									{client.data?.phone}
								</InfoText>
							</FlexContainer>
						</ListItem>
					)}
					{client.data?.email && (
						<ListItem hideIcon={true}>
							<FlexContainer direction="column" width="100%">
								<InfoLabel>{t("common.email")}</InfoLabel>
								<InfoText as="a" href={`mailto:${client.data?.email}`} target="_blank">
									{client.data?.email}
								</InfoText>
							</FlexContainer>
						</ListItem>
					)}
					<ListItem hideIcon={true}>
						<FlexContainer direction="column" width="100%">
							<InfoLabel>{t("common.currency")}</InfoLabel>
							<FlexContainer direction="row" width="100%">
								<InfoText className="bold">{client.data?.main_currency}</InfoText>
							</FlexContainer>
						</FlexContainer>
					</ListItem>
				</FlexContainer>
			</RestaurantInfo>
		</motion.div>
	);
};

export default RestaurantDetail;
